import React from 'react';
import PropTypes from 'prop-types';
import {useFocusBlur} from 'utils/hooks';

const getStrings = (props, focus) => {
  const {value, id, name, autoComplete} = props;
  return {
    container: `form-group${focus || value ? ' do-float' : ''}`,
    name: name || id,
    autoComplete: autoComplete ? 'on' : 'off',
  };
};

const Select = props => {
  const {
    id,
    label,
    value,
    autoFocus,
    handleChange,
    naked,
    topLabel,
    options,
    disabled,
  } = props;
  const {focus, handleFocus, handleBlur} = useFocusBlur(props);
  const {container, autoComplete, name} = getStrings(props, focus);

  return (
    <div className={naked ? 'Select' : container}>
      {topLabel && <label htmlFor={id}>{label}</label>}
      <div className="Select-Container">
        <select
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          className={naked ? '' : 'form-control'}
          id={id}
          name={name || id}
          autoComplete={autoComplete}
          value={value}
          disabled={disabled}
          autoFocus={autoFocus}
        >
          {options.map(({label, value}) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {!topLabel && (
        <label htmlFor={id} className={naked ? '' : 'label-floated'}>
          {label}
        </label>
      )}
    </div>
  );
};

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  naked: PropTypes.bool,
  topLabel: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Select.defaultProps = {
  value: '',
  disabled: false,
  autoComplete: false,
  autoFocus: false,
  naked: false,
  topLabel: false,
};

export default Select;
