import React from 'react';

const Tomato = ({square}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={square}
      height={square}
      viewBox="0 0 640 640"
    >
      <defs>
        <path
          d="M168.5 543.88C71.96 533.66 -2.06 439.87 0.04 325.67C1.39 252.53 33.67 187.77 82.15 147.96C117.49 90.68 175.49 53.14 240.4 53.14C265.33 53.14 289.03 58.69 310.66 68.75C331.65 59.72 354.29 54.76 377.85 54.76C443.33 54.76 500.38 92.96 533.48 151.08C591.75 188.29 630.4 260.49 628.87 343.46C626.73 459.57 546.68 554.57 447.51 562.11C412.58 600.46 365.45 624.07 313.97 624.07C254.75 624.07 202.44 592.84 168.5 543.88"
          id="g3fXtLlE0W"
        ></path>
        <path
          d="M168.5 543.88C141.35 541.01 115.98 531.53 93.59 516.84C58.54 412.55 106.3 261.79 186.23 193.63C183.86 199.83 217.24 214.79 210.99 217.77C110.67 265.65 142.58 454.76 184.05 558.54C183.9 558.88 182.73 561.63 182.58 561.97C177.6 556.26 172.89 550.22 168.5 543.88"
          id="bvruW7aEB"
        ></path>
        <path
          d="M463.83 78.69C492.15 95.58 516.11 120.59 533.48 151.08C591.75 188.29 630.4 260.49 628.87 343.46C626.73 459.57 546.68 554.57 447.51 562.11C412.58 600.46 365.45 624.07 313.97 624.07C306.51 624.07 299.16 623.58 291.94 622.61C289.74 620.26 272.12 601.39 269.91 599.04C514.1 591.84 429.77 124.91 179.2 228.09C191.93 213.58 204.66 199.08 217.39 184.57C235.23 167.05 253.08 149.53 270.92 132.01C271.2 130.33 271.48 128.66 271.75 126.98C284.56 123.76 348.59 107.67 463.83 78.69"
          id="bqrEsTeJk"
        ></path>
        <path
          d="M82.15 147.96C117.49 90.68 175.49 53.14 240.4 53.14C265.33 53.14 289.03 58.69 310.66 68.75C331.65 59.72 354.29 54.76 377.85 54.76C443.33 54.76 500.38 92.96 533.48 151.08C591.75 188.29 630.4 260.49 628.87 343.46C626.73 459.57 546.68 554.57 447.51 562.11C412.58 600.46 365.45 624.07 313.97 624.07C254.75 624.07 202.44 592.84 168.5 543.88C264.74 644.34 407.66 597.98 430.71 520.56C645.75 464.45 574.15 123.52 358.81 166.36C413.31 182.13 421.99 223.03 419.05 276.34C367.11 164.88 189.86 168.44 151.52 268.25C156.07 189.13 201.78 151.93 270.15 150.18C209.38 46.63 131.97 91.73 82.15 147.96"
          id="bi5Xl9yCT"
        ></path>
        <path
          d="M541.65 156.63C595.33 195.18 630.33 264.43 628.87 343.46C626.73 459.57 546.68 554.57 447.51 562.11C412.58 600.46 365.45 624.07 313.97 624.07C295.66 624.07 278.02 621.09 261.38 615.52C328.71 630.16 388.29 607.62 440.77 551.12C612.55 529.33 670.49 291.37 541.65 156.63"
          id="aS309Q2zi"
        ></path>
        <path
          d="M516.87 126.15C522.91 133.96 528.47 142.3 533.48 151.08C485.48 116.2 436.96 109.72 388.07 123.35C431.84 104.86 475 100.43 516.87 126.15"
          id="f1zjN0XMHx"
        ></path>
        <path
          d="M33.49 453.72C11.63 417.7 -0.84 373.48 0.04 325.67C1.39 252.53 33.67 187.77 82.15 147.96C17.16 220.77 -12.32 340.48 33.49 453.72"
          id="a1PkWXjfqn"
        ></path>
        <path
          d="M273.34 317.58C328.05 317.58 371.39 372.44 370.15 440.1C368.9 507.76 323.53 562.61 268.82 562.61C214.11 562.61 170.77 507.76 172.02 440.1C173.27 372.44 218.63 317.58 273.34 317.58"
          id="b3RVdx44Z"
        ></path>
        <path
          d="M266.61 163.82C184.8 120.35 102.55 101.37 19.11 146.35C78.26 93.47 161.57 79.89 256.45 120.62C252.48 39.94 228.79 15.89 204.79 12C237.59 4.38 269.42 20.09 297.99 114.94C357.44 29.41 405.53 -8.32 442.29 1.53C398.44 2.56 365.34 53.13 338.65 133.27C433.47 105.13 499.72 131.15 527.04 230.98C490.05 138.97 410.77 137.05 318.02 163.8C270.72 191.15 342.98 244.34 261.68 333.05C302.54 250.43 233.4 190.58 266.61 163.82"
          id="e9iNQASUZ"
        ></path>
        <path
          d="M310.86 150.5C262.17 218.64 330.88 223.78 261.68 333.05C342.98 244.34 270.72 191.15 318.02 163.8C410.77 137.05 490.05 138.97 527.04 230.98C497.82 138.91 410.97 117.36 310.86 150.5ZM315.06 135.88C322.92 135.01 330.79 134.14 338.65 133.27C364.65 55.19 396.74 5.19 438.92 1.72C438.96 1.66 439.17 1.39 439.54 0.9L439.5 0.89C383.39 42.69 341.91 87.68 315.06 135.88ZM276.36 128.9C265.49 62.25 245.95 14.06 204.8 12C228.79 15.89 252.48 39.94 256.44 120.62C263.08 123.38 269.72 126.14 276.36 128.9ZM281.99 154.86C193.09 110.24 105.11 95.56 19.11 146.35C102.55 101.37 184.81 120.35 266.61 163.82C271.74 160.83 276.86 157.84 281.99 154.86ZM286.19 136.44L278.48 93.58L294.89 136.03L318.42 99.04L304.09 141.49L325.56 139.06L300.74 147.15L283.89 173.23L290.45 146.34L255.93 132.59L286.19 136.44L286.19 136.44"
          id="a1vcnqimM5"
        ></path>
        <path
          d="M145.47 539.77C114.78 467.4 104.65 363.39 142.6 292.92C114.91 375.42 138.68 485.81 168.5 543.88C160.66 543.05 152.98 541.67 145.47 539.77ZM430.71 520.56C457.73 470.43 456.84 405.47 443.28 333.76C471.81 401.77 482.76 465.77 464.05 522.99C452.93 522.18 441.82 521.37 430.71 520.56C430.71 520.56 430.71 520.56 430.71 520.56"
          id="dzGwrXf50"
        ></path>
      </defs>
      <g>
        <g>
          <g>
            <use xlinkHref="#g3fXtLlE0W" opacity="1" fill="#ed3f1f" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#g3fXtLlE0W"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#bvruW7aEB" opacity="1" fill="#df3721" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#bvruW7aEB"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#bqrEsTeJk" opacity="1" fill="#df3721" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#bqrEsTeJk"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#bi5Xl9yCT" opacity="1" fill="#c23122" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#bi5Xl9yCT"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#aS309Q2zi" opacity="1" fill="#a82b21" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#aS309Q2zi"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#f1zjN0XMHx" opacity="1" fill="#9e281f" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#f1zjN0XMHx"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#a1PkWXjfqn" opacity="1" fill="#f9ab87" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#a1PkWXjfqn"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#b3RVdx44Z" opacity="1" fill="#f25c27" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#b3RVdx44Z"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#e9iNQASUZ" opacity="1" fill="#87b027" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#e9iNQASUZ"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#a1vcnqimM5" opacity="1" fill="#769a2a" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#a1vcnqimM5"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#dzGwrXf50" opacity="1" fill="#c23122" fillOpacity="1"></use>
            <g>
              <use
                xlinkHref="#dzGwrXf50"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              ></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Tomato;
