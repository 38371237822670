//https://www.gatsbyjs.org/packages/gatsby-plugin-workerize-loader/
import PomodoroWorker from 'workers/pomodoro.worker';
import {isBrowser} from 'utils';

let pomodoroWorker;

export default function getPomodoroWorker(handleMessage) {
  if (!pomodoroWorker) {
    pomodoroWorker = isBrowser && window.Worker && new PomodoroWorker();
    pomodoroWorker.addEventListener('message', message => {
      console.log('New Message: ', message.data);
      handleMessage(message.data);
    });
  }
  console.log(`${pomodoroWorker ? 'Existing' : 'New'} instance PomodoroWorker`);
  return pomodoroWorker;
}
